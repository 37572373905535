<template>
  <div class="skeleton-profile-limits">
    <skeleton-preloader-profile-layout>
      <template #desktop>
        <div class="skeleton-profile-limits__small-limits">
          <Skeletor
            v-for="n in SMALL_LIMITS_COUNT"
            :key="n"
            class="skeleton-profile-limits__small-limit"
            as="div" />
        </div>

        <div class="skeleton-profile-limits__big-limits">
          <Skeletor
            v-for="n in BIG_LIMITS_COUNT"
            :key="n"
            class="skeleton-profile-limits__big-limit"
            as="div" />
        </div>
      </template>
    </skeleton-preloader-profile-layout>
  </div>
</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';

  const SMALL_LIMITS_COUNT = 3;
  const BIG_LIMITS_COUNT = 2;

</script>

<style src="~/assets/styles/components/skeleton-preloader/profile-limits-page.scss" lang="scss" />
